<template>
  <div>
  <el-dialog
    title="快递修改"
    :visible.sync="show"
    width="50%"
    :modal-append-to-body="false"
    @close="cancel"
    :before-close="cancel"
  >
    <el-form ref="form" :model="form" label-width="160px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="快递公司">
        <el-select v-model="form.code" filterable>
          <el-option v-for="item in expressList" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="电子面单客户号">
        <el-input v-model="form.customerCode"></el-input>
      </el-form-item>
      <el-form-item label="电子面单密码">
        <el-input v-model="form.customerPwd"></el-input>
      </el-form-item>
      <el-form-item label="月结编码">
        <el-input v-model="form.monthCode"></el-input>
      </el-form-item>
      <el-form-item label="网点名称">
        <el-input v-model="form.sendSite"></el-input>
      </el-form-item>
      <el-form-item label="运费支付方式">
        <el-select v-model="form.payType">
          <el-option label="月结" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="禁用">
        <el-switch v-model="form.invalid"></el-switch>
      </el-form-item>
      <el-form-item label="发件人公司">
        <el-input v-model="form.company"></el-input>
      </el-form-item>
      <el-form-item label="仓库选择">
        <el-select v-model="form.wareHouseId" filterable>
          <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="重量区间设置(克)">
        <div v-for="(item, index) in form.weightList" :key="index" class="weight">
          <el-input-number v-model="item.minWeight" :min="0"></el-input-number>
          <span class="line-between">—</span>
          <el-input-number v-model="item.maxWeight" :min="0"></el-input-number>
          <el-button v-if="form.weightList.length !== 1" type="danger" icon="el-icon-delete" size="small" class="add-btn" @click="deleteWeight(index)"></el-button>
          <el-button v-if="index === form.weightList.length - 1" type="primary" icon="el-icon-plus" size="small" class="add-btn" @click="addWeight"></el-button>
        </div>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancel">取 消</el-button>
      <el-button type="primary" size="small" @click="save">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import * as expressService from '@/api/express'
import * as warehouseService from '@/api/warehouse'

const initData = () => {
  return {
    name: '',
    code: '', // 快递公司编码
    customerCode: '', // 电子面单客户号
    customerPwd: '', // 电子面单密码
    monthCode: '', // 月结编码
    sendSite: '', // 网点名称
    payType: 3, // 运费支付方式 1-现付，2-到付，3-月结
    invalid: false, // 是否启用
    company: '', // 发件人公司
    wareHouseId: '', // 仓库id
    weightList: [{ minWeight: 0, maxWeight: 0 }]
  }
}

export default {
  props: {
    currentId: {
      type: [String, Number],
      require: true
    }
  },
  data () {
    return {
      show: true,
      form: {},
      expressList: [],
      warehouseList: []
    }
  },
  mounted () {
    this.getExpressList()
    this.getWarehouse()
    this.fetchData()
  },
  methods: {
    async getExpressList () {
      const res = await expressService.canUseExpressList()
      this.expressList = res
    },
    async getWarehouse () {
      const res = await warehouseService.list()
      this.warehouseList = res
    },
    async fetchData () {
      if (this.currentId) {
        const res = await expressService.detailExpress(this.currentId)
        this.form = res
      } else {
        this.form = initData()
      }
    },
    addWeight () {
      this.form.weightList.push({ minWeight: 0, maxWeight: 0 })
    },
    deleteWeight (index) {
      this.form.weightList.splice(index, 1)
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        if (this.form.id) {
          await expressService.editExpress(this.form) // 编辑
        } else {
          await expressService.addExpress(this.form) // 新建
        }
        this.$notify.success('保存成功！')
        this.$emit('save')
      } catch (error) {
        const message = '保存失败！'
        this.$notify.error(message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px!important;
}
.line-between {
  padding: 0 10px;
}
.add-btn {
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 10px 20px!important;
  max-height: 500px;
  overflow: auto;
}
.el-input, .el-select {
  width: 80%;
}
.weight {
  display: flex;
  margin-bottom: 10px;
  .el-input-number {
    width: 140px;
  }
}
</style>
