<template>
  <div class="container">
    <tableList ref="list" @modify="amodifyExpress"></tableList>
    <edit v-if="showEdit" :currentId="currentId" @cancel="cancelExpress" @save="saveExpress"></edit>
  </div>
</template>

<script>
import edit from './edit'
import tableList from './table'
export default {
  name: 'expressSetting',
  components: {
    edit,
    tableList
  },
  data () {
    return {
      currentId: '', // 当前新建或者编辑对象的id，若新建则为空
      showEdit: false
    }
  },
  methods: {
    amodifyExpress (id) {
      this.currentId = id || ''
      this.showEdit = true
    },
    cancelExpress () {
      this.showEdit = false
    },
    saveExpress () {
      this.showEdit = false
      this.$refs.list.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: left;
  background-color: #fff;
  padding: 10px;
}
</style>
