<template>
<section>
  <div class="operation">
    <el-button type="primary" icon="el-icon-plus" @click="addExpress">新增</el-button>
  </div>
  <el-table :data="lists">
    <el-table-column label="网点编码" prop="name"></el-table-column>
    <el-table-column label="快递公司">
      <template slot-scope="scope">
        {{getExpressName(scope.row.code)}}
      </template>
    </el-table-column>
    <el-table-column label="网点名称" prop="sendSite"></el-table-column>
    <el-table-column label="月结编码" prop="monthCode"></el-table-column>
    <el-table-column label="仓库" prop="wareHouseName"></el-table-column>
    <el-table-column label="禁用">
      <template slot-scope="scope">
        <span v-if="scope.row.invalid">是</span>
        <span v-else>否</span>
      </template>
    </el-table-column>
    <el-table-column label="发件人信息" min-width="280">
      <template slot-scope="scope">
        <p>姓名：{{scope.row.senderName}}</p>
        <p>电话：{{scope.row.senderMobile}}</p>
        <p>地址：{{scope.row.address}}</p>
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="text" @click="editExpress(scope.row.id)">编辑</el-button>
        <el-button type="text" @click="deleteExpress(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</section>
</template>

<script>
import * as expressService from '@/api/express'

export default ({
  data () {
    return {
      lists: [],
      expressList: []
    }
  },
  mounted () {
    this.init()
    this.getExpressList()
  },
  methods: {
    async init () {
      try {
        const res = await expressService.getExpressList()
        this.lists = res
      } catch (error) {
        this.$notify.error('获取列表失败，请重试！')
      }
    },
    async getExpressList () {
      const res = await expressService.canUseExpressList()
      this.expressList = res
    },
    deleteExpress (id) {
      this.$confirm('确定删除这个快递配置吗？', '提示', { type: 'warning' }).then(async () => {
        try {
          await expressService.deleteExpress(id)
          this.init()
          this.$notify.success('删除成功！')
        } catch (error) {
          this.$notify.error('删除失败！')
        }
      })
    },
    editExpress (id) {
      this.$emit('modify', id)
    },
    addExpress () {
      this.$emit('modify')
    },
    getExpressName (code) {
      return this.expressList.find(o => o.code === code).name || '未配置这个快递'
    }
  }
})
</script>

<style lang="scss" scoped>
.operation{
  padding: 20px;
}
</style>
